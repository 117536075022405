import React, {ChangeEvent, FC, useState} from 'react'
import DeleteIcon from '@mui/icons-material/Delete'
import {Box, Button, Grid, TextField, Typography} from '@mui/material'
import {useAccountCreationContext} from '../../context/AccountCreationContext'
import {areCompanyDomainsValid, getCompanyDomain, isCompanyDomainValid, isEmailAddress, isEmailDomain} from '../../utils/accountCreationHelpers'
import {TrackActionEvent} from '../../service/SegmentService'
import {useUser} from '@clerk/clerk-react'
import {AccountDomain} from '../admin/configuration/settings/AccountDomain'

export const AccountCreationFormCompanyDomains: FC = () => {
	const {form, changeFormValue, changeFormValueValid} = useAccountCreationContext()
	const {companyDomains, companyName} = form
	const {user} = useUser()

	const handleAddDomainClicked = () => {
		changeFormValue('companyDomains', [...companyDomains, ''])
		changeFormValueValid('companyDomains', undefined)
		TrackActionEvent('Create Organization', user?.externalId ?? user?.id, {
			company_name: companyName,
			company_domains: companyDomains,
			action: 'add_domain'
		})
	}

	return <Box className='AccountCreationForm_Control'>
		<Typography variant='h4' className='AccountCreationForm_ControlHeader'>
			Connect user base
		</Typography>

		<Typography variant='h6' className='AccountCreationForm_ControlSubheader'>
			Add by domain
		</Typography>
		<Typography variant='subtitle2' className='AccountCreationForm_ControlSubtitle'>
			You can give bulk access by adding an email domain. This allows all users with matching email addresses to access your workspace, eg.@yourcompany.com
		</Typography>
		{companyDomains.map((domain, i) => !domain || isEmailDomain(domain) || !isEmailAddress(domain) ? <AccountCreationFormCompanyDomainControl key={i} index={i}/> : <></>)}
		<Box>
			<Button onClick={handleAddDomainClicked}>+ Add domain</Button>
		</Box>
		<AccountCreationFormCompanyEmailControl/>
	</Box>
}

const AccountCreationFormCompanyEmailControl = () => {
	const {form, error, changeFormValue, changeFormValueValid} = useAccountCreationContext()
	const {companyDomains, companyName} = form
	const {user} = useUser()

	const [domainEmailValidError, setDomainEmailValidError] = useState<boolean>(false)
	const [validError, setValidError] = useState<string>('')
	const [newDomainEmail, setNewDomainEmail] = useState<string>('')

	const showError = domainEmailValidError || !!error.companyDomains
	const helperText = showError ? (validError || error.companyDomains || 'Domain has to follow the example @yourcompany.com') : ''

	const handleSaveEmailDomain = async () => {
		if (!isEmailAddress(newDomainEmail)) {
			setDomainEmailValidError(true)
			setValidError('Your input doesn\'t match an email format. Try a format like name@yourcompany.com')
			return
		}

		if (companyDomains.includes(newDomainEmail)) {
			setDomainEmailValidError(true)
			setValidError('That email already exists. Try adding a different one')
			return
		}
		setDomainEmailValidError(false)
		const updatedDomains = [...companyDomains, newDomainEmail]
		changeFormValue('companyDomains', updatedDomains)
		changeFormValueValid('companyDomains', areCompanyDomainsValid(updatedDomains))
		TrackActionEvent('Create Organization', user?.externalId ?? user?.id, {
			company_name: companyName,
			company_domains: companyDomains,
			action: 'add_domain_email'
		})
		setNewDomainEmail('')
	}

	const handleDeleteDomainClicked = async (domain: string) => {
		const updatedCompanyDomains = companyDomains.filter(companyDomain => companyDomain !== domain)
		changeFormValue('companyDomains', updatedCompanyDomains)
		changeFormValueValid('companyDomains', areCompanyDomainsValid(updatedCompanyDomains))
		TrackActionEvent('Create Organization', user?.externalId ?? user?.id, {
			company_name: companyName,
			company_domains: companyDomains,
			action: 'remove_domain_email'
		})
	}

	const allowedDomainEmails = companyDomains.filter(isEmailAddress)
	return (
		<>
			<Typography variant='h6' className='AccountCreationForm_ControlSubheader'>
				Add by email
			</Typography>
			<Typography variant='subtitle2' className='AccountCreationForm_ControlSubtitle'>
				Add users by entering their email addresses. You can include several users at the same time separated by commas.
			</Typography>
			<TextField variant='outlined' label='User email' placeholder='name@yourcompany.com' fullWidth className='newUserInput' value={newDomainEmail}
			           error={showError}
			           helperText={helperText}
			           onChange={(event) => setNewDomainEmail(event.target.value)} InputProps={{
				endAdornment: <Button color="primary" className='addUserButton' onClick={handleSaveEmailDomain}>Add user</Button>
			}}/>
			{allowedDomainEmails?.length ?
				<Grid container className='sectionContainer'>
					{allowedDomainEmails.map((allowedEmail, index) =>
						<AccountDomain domain={allowedEmail} key={`allowed-email${index}`} isEditAllowed={false} showConfirmDelete={false}
						               handleDeleteClicked={handleDeleteDomainClicked} deleteEventName='domainEmailDeletion' deleteDisabled={false}/>)
					}
				</Grid>
				: ''}
		</>
	)
}

const AccountCreationFormCompanyDomainControl: FC<{ index: number }> = ({
	                                                                        index,
                                                                        }) => {
	const {form, error, changeFormValue, changeFormValueValid, changeFormValueError} = useAccountCreationContext()
	const {user} = useUser()
	const [validError, setValidError] = useState<boolean>(false)
	const {companyDomains, companyName} = form
	const domain = companyDomains[index] ?? ''
	const showError = validError || (!!error.companyDomains && !!domain)
	const helperText = showError ? (error.companyDomains || 'Domain has to follow the example @yourcompany.com') : ''

	const getUpdatedCompanyDomains = (value: string): string[] => [...companyDomains.slice(0, index), value, ...companyDomains.slice(index + 1)]

	const handleDeleteClicked = () => {
		const updatedDomains = [...companyDomains.slice(0, index), ...companyDomains.slice(index + 1)]
		changeFormValue('companyDomains', updatedDomains)
		changeFormValueValid('companyDomains', areCompanyDomainsValid(updatedDomains))
		TrackActionEvent('Create Organization', user?.externalId ?? user?.id, {
			company_name: companyName,
			company_domains: updatedDomains,
			action: 'remove_domain'
		})
	}

	const handleInputChanged = (event: ChangeEvent<HTMLInputElement>) => {
		const updatedDomains = getUpdatedCompanyDomains(event.target.value)
		changeFormValue('companyDomains', updatedDomains)
	}

	const handleInputBlurred = () => {
		const value = getCompanyDomain(domain)
		const updatedDomains = getUpdatedCompanyDomains(value)
		setValidError(value ? !isCompanyDomainValid(value) : false)
		changeFormValue('companyDomains', updatedDomains)
		changeFormValueValid('companyDomains', areCompanyDomainsValid(updatedDomains))
	}

	const handleInputFocus = () => {
		setValidError(false)
		changeFormValueError('companyDomains', undefined)
		changeFormValueValid('companyDomains', undefined)
	}

	return <div className='AccountCreationForm_ControlItem'>
		<TextField variant='outlined' label='Company domain' error={showError} helperText={helperText} value={domain} onFocus={handleInputFocus} onBlur={handleInputBlurred}
		           onChange={handleInputChanged} placeholder='@yourcompany.com'/>
		<DeleteIcon onClick={handleDeleteClicked}/>
	</div>
}