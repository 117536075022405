import { ClerkUserButtonAppearance } from '../types/Clerk'

export const CLERK_DEFAULT_USER_BUTTON_APPEARANCE: ClerkUserButtonAppearance  = {
    elements: {
        avatarBox: {
            width:'30px',
            height: '30px'
        },
        userButtonPopoverCard: {
            width: '225px',
            borderRadius: '8px',
            border: '2px solid rgba(9, 30, 66, 0.14)',
            background: '#FFF',
            padding: '0',
            boxShadow: 'none'
        },
        userPreview__userButton: {
            padding: '10px 0',
            borderBottom: '1px solid rgba(0, 0, 0, 0.12)'
        },
        userButtonPopoverActionButton__manageAccount: {
            padding: '0'
        },
        userButtonPopoverActionButton__signOut: {
            padding: '0'
        },
        userButtonPopoverFooter: {
            display: 'none'
        },
        userPreviewAvatarBox: {
            display: 'none'
        }
    }
}

export const CLERK_ACCOUNT_CREATION_USER_BUTTON_APPEARANCE: ClerkUserButtonAppearance = {
    ...CLERK_DEFAULT_USER_BUTTON_APPEARANCE,
    elements: {
        ...CLERK_DEFAULT_USER_BUTTON_APPEARANCE.elements,
        userButtonPopoverActionButton__manageAccount: {
            padding: '0',
            display: 'none'
        }

    }
}