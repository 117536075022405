import {createContext, FC, PropsWithChildren, useCallback, useContext, useEffect, useMemo, useState} from 'react'
import {Location, useLocation, useNavigate} from 'react-router-dom'
import {AppDialog, AppDialogProps} from '../components/appDialog/AppDialog'
import {Action, createBrowserHistory} from 'history'

export type DialogParams = Omit<AppDialogProps, 'children' | 'open'>

type BeforeNavigateCallback = () => void
type UnhandledNavigateCallback = (from: Location, to: Location) => void

type AppNavigationContextValue = {
    navigate: (route: string) => void
    addNavigationDialog: (dialog: DialogParams) => void
    removeNavigationDialog: (dialog: DialogParams) => void
    addBeforeNavigationCallback: (callback: BeforeNavigateCallback) => void
    removeBeforeNavigationCallback: (callback: BeforeNavigateCallback) => void
    addUnhandledNavigationCallback: (callback: UnhandledNavigateCallback) => void
    removeUnhandledNavigationCallback: ((callback: UnhandledNavigateCallback) => void)
}

const DEFAULT_APP_NAVIGATION_CONTEXT_VALUE: AppNavigationContextValue = {
    navigate: () => {},
    addNavigationDialog: () => {},
    removeNavigationDialog: () => {},
    addBeforeNavigationCallback: () => {},
    removeBeforeNavigationCallback: () => {},
    addUnhandledNavigationCallback: () => {},
    removeUnhandledNavigationCallback: () => {}
}

const AppNavigationContext = createContext<AppNavigationContextValue>(DEFAULT_APP_NAVIGATION_CONTEXT_VALUE)

const history = createBrowserHistory()

export const useAppNavigationContext = () => useContext(AppNavigationContext)

export const AppNavigationContextProvider: FC<PropsWithChildren> = ({ children }) => {
    const [dialogOpen, setDialogOpen] = useState(false)
    const [dialogs, setDialogs] = useState<DialogParams[]>([])
    const [navigateTarget, setNavigateTarget] = useState('')
    const [beforeNavigateCallbacks, setBeforeNavigateCallbacks] = useState<BeforeNavigateCallback[]>([])
    const [unhandledNavigateCallbacks, setUnhandledNavigateCallbacks] = useState<UnhandledNavigateCallback[]>([])
    
    const navigate = useNavigate()
    const location = useLocation()

    const customNavigate = useCallback((route: string) => {
        if (dialogs.length) {
            setNavigateTarget(route)
            setDialogOpen(true)
        } else {
            beforeNavigateCallbacks.forEach(callback => callback())
            navigate(route)
        }
    },[dialogs, navigate, beforeNavigateCallbacks])

    const lastDialog = dialogs.length ? dialogs[dialogs.length - 1] : undefined

    const composedDialogParams: DialogParams | undefined = useMemo(() => lastDialog ? {
        primaryText: lastDialog.primaryText,
        secondaryText: lastDialog.secondaryText,
        secondaryClassName: lastDialog.secondaryClassName,
        text: lastDialog.text,
        title: lastDialog.title,
        onClose: () => {
            lastDialog.onClose()
            setDialogOpen(false)
        },
        onPrimary: () => {
            lastDialog.onPrimary()
            beforeNavigateCallbacks.forEach(callback => callback())
            setDialogOpen(false)
            navigate(navigateTarget)
        },
        onSecondary: lastDialog.onSecondary ? () => {
            lastDialog.onSecondary?.()
            setDialogOpen(false)
        } : undefined        
    } : undefined, [beforeNavigateCallbacks, lastDialog, navigateTarget, navigate])

    const addNavigationDialog = useCallback((dialog: DialogParams) => {
        setDialogs(prev => [...prev, dialog])
    }, [])

    const removeNavigationDialog = useCallback((dialog: DialogParams) => {
        setDialogs(prev => prev.filter(item => item !== dialog))
    }, [])

    const addBeforeNavigationCallback = useCallback((callback: BeforeNavigateCallback) => {
        setBeforeNavigateCallbacks(prev => [...prev, callback])
    }, [])

    const removeBeforeNavigationCallback = useCallback((callback: BeforeNavigateCallback) => {
        setBeforeNavigateCallbacks(prev => prev.filter(item => item !== callback))
    }, [])

    const addUnhandledNavigationCallback = useCallback((callback: UnhandledNavigateCallback) => {
        setUnhandledNavigateCallbacks(prev => [...prev, callback])
    }, [])
    const removeUnhandledNavigationCallback = useCallback((callback: UnhandledNavigateCallback) => {
        setUnhandledNavigateCallbacks(prev => prev.filter(item => item !== callback))
    }, [])

    const value: AppNavigationContextValue = useMemo(() => ({
        navigate: customNavigate,
        addNavigationDialog,
        removeNavigationDialog,
        addBeforeNavigationCallback,
        removeBeforeNavigationCallback,
        addUnhandledNavigationCallback,
        removeUnhandledNavigationCallback
    }),[
        customNavigate, 
        addNavigationDialog, 
        removeNavigationDialog, 
        addBeforeNavigationCallback, 
        removeBeforeNavigationCallback,
        addUnhandledNavigationCallback,
        removeUnhandledNavigationCallback
    ])

    useEffect(() => {
        const unlisten = history.listen((update) => {
            if (update.action === Action.Pop) {
                unhandledNavigateCallbacks.forEach(callback => callback(location, update.location))
                navigate(update.location.pathname)
            }
        })

        return () => {
            unlisten()
        }
    }, [navigate, location, unhandledNavigateCallbacks])
    
    return <AppNavigationContext.Provider value={value}>
        { composedDialogParams ? <AppDialog {...composedDialogParams} open={dialogOpen} /> : <></> }
        {children}
    </AppNavigationContext.Provider>
}