import {Box, Button, ThemeProvider, Typography} from '@mui/material'
import {useNavigate} from 'react-router-dom'
import {CUSTOM_THEME} from '../themes/CustomThemes'
import ErrorIcon from '@mui/icons-material/InsertPageBreak'
import './RouterErrorPage.scss'

export const RouterErrorPage = () => {
    const navigate = useNavigate()
    
    return (
        <ThemeProvider theme={CUSTOM_THEME}>
            <Box className='routerErrorPageContainer'>
                <Box className='routerErrorPageContent'>
                    <Box className='routerErrorPageIcon'>
                        <svg width={0} height={0}>
                            <defs>
                                <linearGradient id='linearColors' x1='15.5' y1='0.331055' x2='15.5' y2='20.6689' gradientUnits='userSpaceOnUse'>
                                    <stop stopColor='#6BC4D0' stopOpacity='0.3'/>
                                    <stop offset='1' stopColor='#C8C4D0' stopOpacity='0.5'/>
                                </linearGradient>
                            </defs>
                        </svg>
                        <ErrorIcon sx={{fontSize: 67, fill: 'url(#linearColors)'}}/>
                    </Box>
                    <Box className='routerErrorPageText'>
                        <Typography className='routerErrorPageTitle'>
                            Oops! page not found
                        </Typography>
                        <Typography className='routerErrorPageDescription'>
                            We're sorry we couldn't find the page you were looking for. You can go back to the home page and search from there.
                        </Typography>
                    </Box>
                    <Button variant='contained' onClick={() => navigate('/')}>
                        Go to home
                    </Button>
                </Box>
            </Box>
        </ThemeProvider>
    )
}