import {Box, Grid, Typography} from '@mui/material'
import ErrorIcon from '@mui/icons-material/Error'
import './NoModelsEnabledError.scss'

export const NoModelsEnabledError = () => <Grid container className='noModelsEnabledPanel'>
	<Box className='noModelsEnabledErrorWrapper'>
		<Box className='noModelsEnabledErrorContainer'>
			<Box>
				<svg width={0} height={0}>
					<defs>
						<linearGradient id='linearColors' x1='15.5' y1='0.331055' x2='15.5' y2='20.6689' gradientUnits='userSpaceOnUse'>
							<stop stopColor='#6BC4D0' stopOpacity='0.3'/>
							<stop offset='1' stopColor='#C8C4D0' stopOpacity='0.5'/>
						</linearGradient>
					</defs>
				</svg>
				<ErrorIcon sx={{fontSize: 170, fill: 'url(#linearColors)'}}/>
			</Box>
			<Typography variant='h3'>There are no AI models enabled for you</Typography>
			<Typography variant='h5'>Please contact your administrator and ask them to enable at least one AI model for you to use Narus.</Typography>
		</Box>
	</Box>
</Grid>