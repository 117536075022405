import { AccountCreationFormAdminEmails } from '../components/accountCreation/AccountCreationFormAdminEmails'
import { FC } from 'react'
import { AccountCreationFormCompanyDomains } from '../components/accountCreation/AccountCreationFormCompanyDomains'
import { AccountCreationFormCompanyName } from '../components/accountCreation/AccountCreationFormCompanyName'
import { AccountCreationFormModels } from '../components/accountCreation/AccountCreationFormModels'
import { Config, ContextValue, FormMap, FormTemplate, StepsState } from '../types/AccountCreation'

export const DEFAULT_ACCOUNT_CREATION_FORM_TEMPLATE: FormTemplate = {
    adminEmails: [],
    companyDomains: [],
    companyName: '',
    models: []
}

export const DEFAULT_ACCOUNT_CREATION_FORM_VALID: FormMap<boolean | undefined> = {
    adminEmails: true,
    companyDomains: undefined,
    companyName: undefined,
    models: true
}

export const DEFAULT_ACCOUNT_CREATION_ERROR: FormMap<string | undefined> = {
    adminEmails: undefined,
    companyDomains: undefined,
    companyName: undefined,
    models: undefined
}

export const DEFAULT_ACCOUNT_CREATION_STATE: StepsState = {
    '0': 'idle',
    '1': 'idle',
    '2': 'idle'
}

export const ACCOUNT_CREATION_COMPONENT_MAP: FormMap<FC> = {
    adminEmails: AccountCreationFormAdminEmails,
    companyDomains: AccountCreationFormCompanyDomains,
    companyName: AccountCreationFormCompanyName,
    models: AccountCreationFormModels,
}

export const DEFAULT_ACCOUNT_CREATION_CONTEXT_VALUE: ContextValue = {
    form: DEFAULT_ACCOUNT_CREATION_FORM_TEMPLATE,
    stepsState: DEFAULT_ACCOUNT_CREATION_STATE,
    valid: DEFAULT_ACCOUNT_CREATION_FORM_VALID,
    error: DEFAULT_ACCOUNT_CREATION_ERROR,
    changeFormValue: () => {},
    changeFormValueValid: () => {},
    changeFormValueError: () => {},
    resetStepState: () => {},
    submitStep: () => {}
}

export const ACCOUNT_CREATION_CONFIG: Config = {
    '0': {
        title: 'Basic Information',
        formTitle: 'Basic Information',
        page: '/create-account-basic-information',
        controls: ['companyName', 'companyDomains'],
        validators: ['companyName'],
        actionTitle: 'Continue',
        pageAnalytics: 'Company onboarding basic info'
    },
    '1': {
        title: 'Define Admins',
        formTitle: 'Define who the admins will be',
        page: '/create-account-assign-admins',
        controls: ['adminEmails'],
        validators: ['adminEmails'],
        actionTitle: 'Continue',
        pageAnalytics: 'Company onboarding admin accounts'
    },
    '2': {
        title: 'Add AI models',
        formTitle: 'Connect AI models',
        page: '/create-account-connect-ai',
        controls: ['models'],
        validators: ['models'],
        actionTitle: 'Finalize process',
        pageAnalytics: 'Company onboarding AI models'
    }
}

export const ACCOUNT_CREATION_ERRORS: { prop: keyof FormTemplate, message: string, error: string }[] = [
    {
        prop: 'companyName',
        message: 'This company name has already been used in Narus. Try a different one.',
        error: 'Invalid request, suffix is in use'
    }, 
    {
        prop: 'companyName',
        message: 'This company name has already been used in Narus. Try a different one.',
        error: 'Invalid request, organization name provided is in use'
    },
    {
        prop: 'companyName',
        message: 'Company name is too long.',
        error: 'Invalid request, suffix is very long'
    },
    {
        prop: 'companyDomains',
        message: 'A domain is already being used.',
        error: 'Invalid request, there is an organization already using an allowed email you used'
    },
    {
        prop: 'companyDomains',
        message: 'Can\'t use major email provider.',
        error: 'Invalid request, can\'t use allowed emails of major email providers'
    },
]