import {useCallback, useState} from 'react'
import {useUser} from '@clerk/clerk-react'
import {Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider} from '@mui/material'
import {TrackActionEvent} from '../../../service/SegmentService'
import {NEED_HELP} from '../../../constants/AppMenuConstants'
import {USER_DOCUMENTATION_URL, USER_SUPPORT_EMAIL} from '../../../constants/Links'
import {CustomSideItemWithPopover} from './CustomSideItemWithPopover'
import EmailIcon from '@mui/icons-material/Email'
import InfoIcon from '@mui/icons-material/Info'
import './CustomSideItemHelp.scss'

export const CustomSideItemHelp = () => {
    const {user} = useUser()
    const [emailDialogOpen, setEmailDialogOpen] = useState(false)
    const userId = user?.externalId ?? user?.id

    const handleDocumentationClick = useCallback(() => {
		TrackActionEvent('Menu clicked', userId, {path: 'user_documentation'})
    }, [userId])

    const handleEmailClick = useCallback(() => {
		TrackActionEvent('Menu clicked', userId, {path: 'user_support_email'})
        setEmailDialogOpen(true)
    }, [userId])

    const handleCloseEmailDialog = useCallback(() => {
        setEmailDialogOpen(false)
    }, [])

    return <>
        <CustomSideItemWithPopover item={NEED_HELP}>
            <Box className='sideItemHelpContainer'>
                <Button className='sideItemHelpButton' startIcon={<InfoIcon/>} href={USER_DOCUMENTATION_URL} target={'narus_user_documentation'} onClick={handleDocumentationClick}>User documentation</Button>
                <Button className='sideItemHelpButton' startIcon={<EmailIcon/>} onClick={handleEmailClick}>Help & Support</Button>
            </Box>
        </CustomSideItemWithPopover>
        <Dialog open={emailDialogOpen} onClose={handleCloseEmailDialog}>
            <DialogTitle>
                <span>Help & Support</span>
            </DialogTitle>
            <Divider/>
            <DialogContent>
                <DialogContentText>
                    Have questions or need support? Contact us at {USER_SUPPORT_EMAIL} for assistance.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant='contained' onClick={handleCloseEmailDialog}>Close</Button>
            </DialogActions>
        </Dialog>
    </>
}